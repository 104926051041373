import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import DialogListContent from '@/components/dialog-list/DialogListContent'
import EmptyList from '@/components/dialog-list/EmptyList'
import { loadMoreChats, deleteChat, updateChat, setLastMsg, changeUnreadCount } from '@/redux/actions/chatMap'
import { deleteChatMsgs } from '@/redux/actions/messageMap'
import { setChatListQuery } from '@/redux/actions/app'
import {
  loadMoreChatsApi,
  clearChatMsgsApi,
  deleteChatApi,
  startChatApi,
  updateChatApi,
  getGroupChatInfoApi,
} from '@/services/chat'
import { getCallmeUserApi, reportBlockUserApi } from '@/services/user'
import { PubSub } from 'pubsub-js'
import { setUserInfoCache } from '@/redux/actions/userMap'
import { useNavigate } from 'react-router-dom'
import UserProfile from '@/components/user-profile/UserProfile'
import { Modal, Toast, Dialog, NoticeBar, Form, Input } from 'antd-mobile'
import { setUserBlockApi } from '@/services/user'
import cookie from 'react-cookies'

import './DialogList.css'
import GroupChatEditor from '@/components/group-chat/GroupChatEditor'
import { wsJoinRoom, wsLeaveRoom } from '@/services/websocket'

const queryString = require('query-string')

const DialogList = function (props) {
  const {
    openChat,
    chatMap,
    loadMoreChats,
    app,
    setChatListQuery,
    messageMap,
    user,
    userMap,
    deleteChat,
    deleteChatMsgs,
    setLastMsg,
    changeUnreadCount,
    updateChat,
    handleShowTips,
    broadcast,
  } = props
  const navigate = useNavigate()

  const joinGroupChatRef = useRef(null)
  const modalControlRef = useRef(null)

  useEffect(() => {
    if (user.id) {
      if (!app.chatListQuery_fromId) {
        handleLoadMore()
      }
      // callme 链接进来的
      const parsed = queryString.parse(window.location.search)
      const callmeToken = parsed['call']
      if (callmeToken) {
        navigate('/chat')
        openCallme(callmeToken)
        return
      }

      const joinGroupToken = parsed['join_group']
      if (joinGroupToken) {
        navigate('/chat')
        handleShowGroupInfo(joinGroupToken)
      }
    }
  }, [user])

  async function openCallme(callmeToken) {
    const { data, code, msg } = await getCallmeUserApi(callmeToken)
    if (code !== 200) {
      setTimeout(() => {
        Toast.show({ icon: 'fail', content: msg })
      }, 1000)
      return
    }

    if (!data) {
      setTimeout(() => {
        Toast.show({ icon: 'fail', content: '该用户的分享链接已失效' })
      }, 1000)
      return
    }
    // 更新用户缓存
    setUserInfoCache(data)
    Modal.show({
      closeOnMaskClick: true,
      closeOnAction: true,
      content: <UserProfile user={data} hideRemark={true} showOpt={false} />,
      onAction: handleStartChat(callmeToken),
      actions: [
        {
          key: 'start_chat',
          text: '开始聊天',
          primary: true,
        },
      ],
    })
    Toast.show('查询对方信息成功')
  }

  function handleStartChat(callmeToken) {
    return async () => {
      const { code, data } = await startChatApi('callme', callmeToken)
      if (code !== 200) return
      if (!data) return
      openChat(data)
    }
  }

  async function handleShowGroupInfo(joinGroupToken) {
    const { data, code, msg } = await getGroupChatInfoApi(joinGroupToken)
    if (code !== 200 || !data) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    setTimeout(() => {
      Toast.show('查询群组信息成功')
      joinGroupChatRef.current?.setFieldsValue({ title: data.title, memberCount: data.member_count })
    }, 100)
    modalControlRef.current = Modal.show({
      closeOnMaskClick: false,
      content: (
        <>
          <Form ref={joinGroupChatRef}>
            <Form.Item name="title" label="群聊名称">
              <Input disabled />
            </Form.Item>
            <Form.Item name="memberCount" label="成员数">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="password"
              label="口令"
              rules={[
                {
                  min: 1,
                  max: 10,
                  type: 'string',
                  message: '口令长度在10以内',
                },
              ]}>
              <Input placeholder="不填写则不需要口令" />
            </Form.Item>
          </Form>
        </>
      ),
      onAction: handleAction(joinGroupToken),
      actions: [
        {
          key: 'start_chat',
          text: '加入群聊',
          primary: true,
        },
        {
          key: 'cancel',
          text: '取消',
        },
      ],
    })
  }

  function handleAction(groupToken) {
    return async (e) => {
      if (e.key === 'start_chat') {
        const password = joinGroupChatRef.current?.getFieldValue('password')
        const { code, data, msg } = await startChatApi('join_group', groupToken, password)
        if (code !== 200 || !data) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        openChat(data)
        wsJoinRoom(user.id, data.id)
        modalControlRef.current.close()
      } else if (e.key === 'cancel') {
        modalControlRef.current.close()
      }
    }
  }

  function getLastChatId() {
    if (!app.chatListQuery_fromId) {
      return null
    }
    let chats = []
    Object.keys(chatMap || {}).forEach((chatid) => {
      let chat = chatMap[chatid]
      if (!(chat.isGroupChat || chat.is_group_chat)) {
        chats.push(chat)
      }
    })
    // 获取创建时间最早的聊天
    chats.sort((a, b) => {
      return a.createTs - b.createTs
    })
    if (chats.length > 0) {
      return chats[0].id
    }
    return null
  }

  async function handleLoadMore() {
    const { data, code } = await loadMoreChatsApi(getLastChatId(), app.chatListQuery_limit)
    if (code !== 200) {
      setChatListQuery(app.chatListQuery_fromId, false)
      return
    }

    const chatsLength = data.chats.length
    if (chatsLength) {
      setChatListQuery(data.chats[chatsLength - 1].id, data.has_more)
    } else {
      // if (Object.keys(chatMap).length === 0) {
      //   handleShowTips()
      // }
    }
    loadMoreChats(data.chats)
    return data.has_more
  }

  async function handleDeleteChat(chatid) {
    const { code, msg } = await deleteChatApi(chatid, chatMap[chatid].isGroupChat)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    wsLeaveRoom(user.id, chatid)
    deleteChat(chatid)
    deleteChatMsgs(chatid)
  }

  async function handleCollectChat(chat, isCollect) {
    const { code, msg } = await updateChatApi(chat.id, isCollect)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    updateChat(chat.chatid, { isCollect: isCollect })
    if (isCollect) {
      Toast.show('聊天已收藏')
    } else {
      Toast.show('聊天已取消收藏')
    }
  }

  async function handleBlockUser(chat, isBlock) {
    const { code, msg } = await setUserBlockApi(chat.toUid, chat.chatid, isBlock)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    updateChat(chat.chatid, { isBlock: isBlock })
    Toast.show({ icon: 'success', content: msg })
    if (isBlock) {
      // 删除聊天
      deleteChat(chat.chatid)
      deleteChatMsgs(chat.chatid)
    }
  }

  function handleReportAction(chat) {
    return async (node) => {
      if (node.key !== 'cancel') {
        const { code, msg } = await reportBlockUserApi(chat.toUid, chat.chatid, node.key)
        if (code === 200) {
          Toast.show({ icon: 'success', content: msg })
          // 删除聊天
          deleteChat(chat.chatid)
          deleteChatMsgs(chat.chatid)
        } else {
          Toast.show({ icon: 'fail', content: msg })
        }
      }
    }
  }

  async function handleReport(chat) {
    await Dialog.show({
      title: '举报并拉黑对方？',
      closeOnAction: true,
      closeOnMaskClick: true,
      content: '请选择以下举报类型，感谢您的积极反馈。恶意举报将遭到系统封禁',
      onAction: handleReportAction(chat),
      actions: [
        [
          {
            key: 'ylzp',
            text: '引流诈骗',
          },
          {
            key: 'zzmg',
            text: '政治敏感',
          },
        ],
        [
          {
            key: 'wrmm',
            text: '侮辱谩骂',
          },
          {
            key: 'dssq',
            text: '低俗色情',
          },
        ],
        [
          {
            key: 'eysp',
            text: '恶意刷屏',
          },
          {
            key: 'others',
            text: '其他违法',
          },
        ],
        {
          key: 'cancel',
          text: '取消举报',
        },
      ],
    })
  }

  async function handleClearMsgs(chat) {
    const { code, msg } = await clearChatMsgsApi(chat.chatid, chat.isGroupChat)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    setLastMsg(chat.chatid, null, null, null)
    changeUnreadCount(chat.chatid, 0, true)
    deleteChatMsgs(chat.chatid)
    Toast.show({ icon: 'success', content: '已清空聊天记录' })
  }

  return (
    <div className="dialog-wrapper">
      {broadcast && broadcast['chat_list'] && <NoticeBar content={broadcast['chat_list']} color="alert" />}
      {Object.keys(chatMap).length === 0 && <EmptyList isTryUser={user.user_type === 'try'} />}
      <DialogListContent
        userMap={userMap}
        chatMap={chatMap}
        messageMap={messageMap}
        openChat={openChat}
        onLoadMore={handleLoadMore}
        hasMore={app.chatListQuery_hasMore}
        user={user}
        handleDeleteChat={handleDeleteChat}
        handleCollectChat={handleCollectChat}
        handleBlockUser={handleBlockUser}
        handleClearMsgs={handleClearMsgs}
        handleReport={handleReport}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
    userMap: state.userMap,
    chatMap: state.chatMap,
    messageMap: state.messageMap,
  }
}

function mapDispatchProps(dispatch) {
  return {
    loadMoreChats: (chats) => dispatch(loadMoreChats(chats)),
    setChatListQuery: (fromId, hasMore) => dispatch(setChatListQuery(fromId, hasMore)),
    deleteChat: (chatid) => dispatch(deleteChat(chatid)),
    updateChat: (chatid, data) => dispatch(updateChat(chatid, data)),
    setUserInfoCache: (user) => dispatch(setUserInfoCache(user)),
    deleteChatMsgs: (chatid) => dispatch(deleteChatMsgs(chatid)),
    changeUnreadCount: (chatid, count, isCover) => dispatch(changeUnreadCount(chatid, count, isCover)),
    setLastMsg: (chatid, lastMsgType, lastMsgContent, lastMsgDate) =>
      dispatch(setLastMsg(chatid, lastMsgType, lastMsgContent, lastMsgDate)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(DialogList)
