import { connect } from 'react-redux'
import DialogHeader from '@/components/dialog-detail/DialogHeader'
import MessageList from '@/components/dialog-detail/message-list/MessageList'
import Inputbox from '@/components/dialog-detail/inputbox/Inputbox'
import {
  addMsg,
  insertMsgs,
  updateMsgsStatus,
  deleteMsg,
  setMsgMeta,
  updateMsgContent,
} from '@/redux/actions/messageMap'
import { wsSendMsg } from '@/services/websocket'
import { setMsgMapQuery } from '@/redux/actions/app'
import { loadMoreMessagesApi } from '@/services/chat'
import { wsTyping } from '@/services/websocket'
import { PubSub } from 'pubsub-js'
import { useEffect, useState, useRef } from 'react'
import { setLastMsg, updateChat } from '@/redux/actions/chatMap'
import { setUserInfoCache, updateUserInfoCache } from '@/redux/actions/userMap'
import './DialogDetail.css'
import { NoticeBar, Toast } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
import cookie from 'react-cookies'
import { getUsersBaseInfoApi } from '@/services/user'
import { trackApi } from '@/services/base'

const DialogDetail = function (props) {
  const {
    onClickBack,
    app,
    user,
    chatid,
    currChat,
    chatMap,
    messageMap,
    addMsg,
    insertMsgs,
    setMsgMapQuery,
    showUserInfo,
    userMap,
    setUserInfoCache,
    updateUserInfoCache,
    updateMsgsStatus,
    updateMsgContent,
    setLastMsg,
    updateChat,
    allUnreadMsgCount,
    deleteMsg,
    setMsgMeta,
    broadcast,
    setGroupUids,
    setUserMapMany,
  } = props

  const msgBottomRef = useRef(null)

  const [isTyping, setIsTyping] = useState(false)
  const [receiveNewMsg, setReceiveNewMsg] = useState(false)

  let navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const { pathname } = location

    window.onpopstate = function () {
      onClickBack()
      navigate(pathname)
    }

    let wsBus = PubSub.subscribe('message', (topic, wsMsg) => {
      switch (wsMsg.func) {
        case 'typing':
          return handleTyping(wsMsg)()
        case 'delete_msg':
          return handleDeleteMsg(wsMsg)
        case 'recv_msg':
          return handleRecvMsg(wsMsg)()
        case 'set_msg_meta':
          return handleSetMsgMeta(wsMsg)()
        default:
          return
      }
    })

    return () => {
      PubSub.unsubscribe(wsBus)
      window.onpopstate = null
    }
  }, [])

  function handleDeleteMsg(wsMsg) {
    if (chatid === wsMsg.data.chatid) {
      Toast.show('用户撤回一条消息')
    }
  }

  function handleTyping(wsMsg) {
    let timer = null
    return () => {
      if (wsMsg.data.chatid === chatid) {
        if (!isTyping) {
          setIsTyping(true)
        }
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          setIsTyping(false)
        }, 2000)
      }
    }
  }

  function handleSetMsgMeta(wsMsg) {
    const { chatid, message_id, meta_data } = wsMsg.data
    setMsgMeta(chatid, message_id, meta_data)
  }

  function handleRecvMsg(wsMsg) {
    let timer = null
    return () => {
      // 不是本聊天的对话
      if (wsMsg.data.chatid !== chatid) {
        if (!receiveNewMsg) {
          if (wsMsg.data.chatid !== 'a7973381807c28c19ffccb3a73779c7a') {
            setReceiveNewMsg(true)
          }
        }
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          setReceiveNewMsg(false)
        }, 2000)
      }
    }
  }

  const lastSend = useRef({ ts: new Date().getTime() })

  const beforeSend = (uid, toUid) => {
    const maxCountBeforeReply = 3
    let forceGreenCount = 8
    if (user.likes > 50) {
      forceGreenCount = 3
    } else if (user.likes >= 30) {
      forceGreenCount = 6
    } else if (user.likes > 20) {
      forceGreenCount = 8
    } else {
      forceGreenCount = 12
    }
    // let ret = { canSend: true, failMsg: '', forceGreen: false }

    // 聊天前6句消息将强制使用绿色模式过滤
    let systemMsgCount = 0
    let userMsgCount = 0
    let uidMsgCount = 0
    let toUidMsgCount = 0
    let uidTextMsgCount = 0
    let allSendMsgs = []

    const msgIds = Object.keys(messageMap[chatid] || {})
    for (let i = 0; i < msgIds.length; i++) {
      const message_id = msgIds[i]
      let message = messageMap[chatid][message_id]
      if (message.msgStatus === 'sendFailed') {
        continue
      }
      // 系统消息
      if (
        message.msgType === 'system' ||
        message.msgContent.indexOf('你好，我是通过') !== -1 ||
        message.msgContent.indexOf('【漂流瓶】') !== -1
      ) {
        systemMsgCount += 1
        continue
      }
      // 用户消息
      if (message.msgType === 'text' || message.msgType === 'audio') {
        userMsgCount += 1
        // 用户发的消息数大于20直接return，防止过多的循环
        if (userMsgCount >= 20) {
          return { canSend: true, failMsg: '', forceGreen: false }
        }
      }
      // 用于判断对方是否回复
      if (message.uid) {
        if (message.uid === toUid) {
          toUidMsgCount += 1
        } else if (message.uid === uid) {
          uidMsgCount += 1
          if (message.msgType === 'text') {
            uidTextMsgCount += 1
            allSendMsgs.push(message)
          }
        }
      }
    }
    allSendMsgs.sort((a, b) => {
      return a.ts - b.ts
    })
    allSendMsgs = allSendMsgs.map((e) => e.msgContent)
    // console.log(systemMsgCount, uidTextMsgCount, uidMsgCount, allSendMsgs)
    // 刚建立的聊天，已经发送了3条消息，对方没法消息，不允许发送
    if (systemMsgCount > 0 && userMsgCount <= 6) {
      if (uidMsgCount >= maxCountBeforeReply && toUidMsgCount === 0) {
        return { canSend: false, failMsg: '对方回复后才可再次发送', forceGreen: false }
      }
    }
    // 清空聊天记录后再发 不受限制
    let forceGreen = uidTextMsgCount < forceGreenCount
    if (systemMsgCount === 0) forceGreen = false
    // console.log(systemMsgCount, uidTextMsgCount)
    const allSendMsgContent = allSendMsgs.join('')
    return { canSend: true, failMsg: '', forceGreen, allSendMsgContent }
  }

  const [isWaitGpt, setIsWaitGpt] = useState(false)
  const handleChatGpt = async (robotId, chatid, userMsg, newMessageId, lastMessageId, newMessageTs) => {
    setIsWaitGpt(true)
    const data = { robotId, chatid, userMsg, newMessageId, lastMessageId }
    const url = '/api/v1/gpt/chat'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    const reader = response.body.getReader()

    // 处理流数据
    const decoder = new TextDecoder('utf-8')
    let respText = ''
    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      const chunk = decoder.decode(value, { stream: true })
      respText = respText + chunk
      // console.log('Received chunk:', chunk)
      updateMsgContent(chatid, newMessageId, chunk)
    }
    setLastMsg(chatid, 'text', respText, newMessageTs)
    setIsWaitGpt(false)
  }

  const handleSendMsg = (msgType, msgContent, msgMeta) => {
    if (isWaitGpt) {
      Toast.show({ icon: 'fail', content: '请等待回复完后再发送消息' })
      return false
    }
    // gpt聊天不允许发送除文字信息外的信息
    if (chatMap[chatid].isGpt && msgType !== 'text') {
      Toast.show({ icon: 'fail', content: '当前仅支持文字消息' })
      return false
    }
    const ts = new Date().getTime()
    if (ts - lastSend.ts < 600) {
      lastSend.ts = ts
      Toast.show({ icon: 'fail', content: '消息发送过快，禁止刷屏' })
      return false
    }
    const uid = user.id
    const { toUid, isGroupChat } = chatMap[chatid]

    const { canSend, failMsg, forceGreen, allSendMsgContent } = beforeSend(uid, toUid)
    if (!msgMeta) msgMeta = {}
    msgMeta['all_msg_content'] = allSendMsgContent + msgContent
    if (!canSend) {
      Toast.show({ icon: 'fail', content: failMsg })
      return false
    }
    const tmp = addMsg(uid, chatid, msgType, msgContent, msgMeta)
    const message_id = tmp.payload.id
    try {
      wsSendMsg(message_id, uid, toUid, chatid, msgType, msgContent, msgMeta, isGroupChat, forceGreen)
      setLastMsg(chatid, msgType, msgContent, ts)
      // 如果是和gpt的聊天
      if (chatMap[chatid].isGpt) {
        const robotId = chatMap[chatid].toUid
        const robotMsg = addMsg(robotId, chatid, 'text', '', { is_gpt: true })
        const newMessageId = robotMsg.payload.id
        const newMessageTs = robotMsg.payload.ts
        handleChatGpt(robotId, chatid, msgContent, newMessageId, message_id, newMessageTs)
      }
    } catch (error) {
      updateMsgsStatus(chatid, [message_id], 'sendFailed')
      Toast.show({ icon: 'fail', content: '消息发送失败，请刷新页面后重新发送' })
      const message = error?.message
      const track = error?.track
      trackApi([
        {
          event: 'error',
          page: 'chat',
          widget: 'send_msg',
          extra: { message, track, uid, chatid, msgType, msgContent, msgMeta },
        },
      ])
      return false
    }
    setTimeout(() => {
      msgBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    lastSend.ts = ts
    return true
  }

  function getOldestMessageId() {
    let messages = []
    Object.keys(messageMap[chatid] || {}).forEach((message_id) => {
      let message = messageMap[chatid][message_id]
      if (message.msgType !== 'system') messages.push(message)
    })

    messages.sort((a, b) => {
      return a.ts - b.ts
    })
    if (messages.length > 0) {
      return messages[0].id
    }
    return null
  }

  // 针对公共群聊的情况，获取userMap中没有的用户信息
  async function updateLocalUserMap(messages) {
    const uids = {}
    messages.forEach((e) => {
      if (e.uid && !userMap[e.uid]) {
        uids[e.uid] = 1
      }
    })
    const tmp = Object.keys(uids)
    if (tmp.length > 0) {
      const { code, data } = await getUsersBaseInfoApi(tmp)
      if (code === 200) setUserMapMany(data)
    }
  }

  async function handleLoadMore() {
    if (!chatid) {
      return []
    }
    const { data, code } = await loadMoreMessagesApi(
      chatid,
      getOldestMessageId(),
      app.msgMapQuery_limit,
      chatMap[chatid]?.isGroupChat || false,
      cookie.load('is_debug')
    )
    if (code !== 200) {
      setMsgMapQuery(chatid, null, false)
      return
    }
    const messagesLength = data.messages.length
    if (messagesLength) {
      setMsgMapQuery(chatid, data.messages[messagesLength - 1].id, data.has_more)
    }
    insertMsgs(data.messages)
    if (chatMap[chatid].isGroupChat) {
      updateLocalUserMap(data.messages)
    }
    return data.messages
  }

  function handleInputMsg() {
    const uid = user.id
    let timer = null
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        const { toUid, isGroupChat } = chatMap[chatid]
        if (!isGroupChat) {
          wsTyping(uid, toUid, chatid)
        }
      }, 500)
    }
  }

  function getChatInfo() {
    let info = {
      avatarUrl: chatMap[chatid]?.avatar || '',
      chatTitle: chatMap[chatid]?.title,
      chatStatus: chatMap[chatid]?.status,
      chatUid: chatMap[chatid]?.uid,
      isGroupChat: chatMap[chatid]?.isGroupChat,
      avatarColor: chatMap[chatid]?.avatarColor,
      memberCount: Object.keys(chatMap[chatid]?.memberUids || {}).length,
      onlineMemberCount: Object.keys(chatMap[chatid]?.onlineUids || {}).length,
    }

    const toUid = chatMap[chatid]?.toUid
    if (toUid && userMap[toUid]) {
      const toUser = userMap[toUid]
      if (toUser.avatar) {
        info.avatarUrl = toUser.avatar
      }
      if (toUser.remark_name || toUser.nickname) {
        info.chatTitle = toUser.remark_name || toUser.nickname
      }
      if (toUser.status) {
        info.chatStatus = toUser.status
      }
    }
    return info
  }

  function checkCanSendFile() {
    const uids = [currChat.toUid, currChat.to_uid, user.id]
    if (uids.indexOf('635bb18fe70cf88f55e04368') !== -1) return 1
    // 聊天大厅禁止发送文件
    if (chatid === 'a7973381807c28c19ffccb3a73779c7a') return -2
    if (chatMap[chatid].isGroupChat) return 1
    // 对方发送的消息少于5条，不可发送图片
    let messages = []
    Object.keys(messageMap[chatid] || {}).forEach((message_id) => {
      let message = messageMap[chatid][message_id]
      if (message.uid && message.uid !== user.id) {
        messages.push(message)
      }
    })
    if (messages.length < 5) {
      return -1
    }
    return 1
  }

  return (
    <div className="dialog-detail-wrapper">
      <DialogHeader
        showUserInfo={showUserInfo}
        onClickBack={onClickBack}
        chatInfo={chatMap[chatid] || {}}
        {...getChatInfo()}
        toUid={chatMap[chatid]?.toUid || chatMap[chatid]?.to_uid}
        setUserInfoCache={setUserInfoCache}
        updateUserInfoCache={updateUserInfoCache}
        updateChat={updateChat}
        chatid={chatid}
        allUnreadMsgCount={allUnreadMsgCount}
        user={user}
        isShowRematch={currChat.isShowRematch}
        setGroupUids={setGroupUids}
        setUserMapMany={setUserMapMany}
      />
      {broadcast && broadcast['chat'] && <NoticeBar content={broadcast['chat']} color="alert" />}
      <MessageList
        updateUserInfoCache={updateUserInfoCache}
        innerRef={msgBottomRef}
        user={user}
        uid={user.id}
        itemMap={messageMap[chatid]}
        onLoadMore={handleLoadMore}
        msgMapQuery={app.msgMapQuery[chatid]}
        toUid={chatMap[chatid]?.toUid}
        chatid={chatid}
        currChat={chatMap[chatid]}
        isTyping={isTyping}
        deleteMsg={deleteMsg}
        setMsgMeta={setMsgMeta}
        receiveNewMsg={receiveNewMsg}
        isGroupChat={chatMap[chatid]?.isGroupChat}
        userMap={userMap}
      />
      <Inputbox
        isWaitGpt={isWaitGpt}
        chatid={chatid}
        toUid={currChat.toUid}
        onSendMsg={handleSendMsg}
        onInputMsg={handleInputMsg}
        checkCanSendFile={checkCanSendFile}
        isGroupChat={chatMap[chatid]?.isGroupChat}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
    userMap: state.userMap,
    chatMap: state.chatMap,
    messageMap: state.messageMap,
  }
}

function mapDispatchProps(dispatch) {
  return {
    addMsg: (uid, chatid, msgType, msgContent, msgMeta) => dispatch(addMsg(uid, chatid, msgType, msgContent, msgMeta)),
    insertMsgs: (messages) => dispatch(insertMsgs(messages)),
    setMsgMapQuery: (chatid, fromId, hasMore) => dispatch(setMsgMapQuery(chatid, fromId, hasMore)),
    setUserInfoCache: (user) => dispatch(setUserInfoCache(user)),
    updateMsgsStatus: (chatid, messageId, status) => dispatch(updateMsgsStatus(chatid, messageId, status)),
    updateMsgContent: (chatid, messageId, msgContent) => dispatch(updateMsgContent(chatid, messageId, msgContent)),
    setLastMsg: (chatid, lastMsgType, lastMsgContent, lastMsgDate) =>
      dispatch(setLastMsg(chatid, lastMsgType, lastMsgContent, lastMsgDate)),
    updateUserInfoCache: (uid, data) => dispatch(updateUserInfoCache(uid, data)),
    updateChat: (chatid, data) => dispatch(updateChat(chatid, data)),
    deleteMsg: (chatid, messageId) => dispatch(deleteMsg(chatid, messageId)),
    setMsgMeta: (chatid, messageId, metaData) => dispatch(setMsgMeta(chatid, messageId, metaData)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(DialogDetail)
