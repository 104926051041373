import { Navigate } from 'react-router-dom'
const queryString = require('query-string')

export default function ActivePage() {
  let url = '/discover'
  const parsed = queryString.parse(window.location.search)
  if (parsed.token) {
    url = '/discover?bind=' + parsed.token
  }

  return <Navigate to={url} />
}
