import { debugUserApi } from '@/services/crm'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import cookie from 'react-cookies'
const queryString = require('query-string')

// http://127.0.0.1:3000/debug?code=nmliao2023&uid=64103337484d58e2ed844e4f

export default function DebugPage() {
  const navigate = useNavigate()

  useEffect(() => {
    let cookieTime = new Date(new Date().getTime() + 999 * 24 * 3600 * 1000)
    cookie.save('is_debug', 1, { expires: cookieTime })

    async function fetchData() {
      const parsed = queryString.parse(window.location.search)
      const { code, data } = await debugUserApi(parsed.uid, parsed.code)
      console.log(data)
      if (code === 200) {
        navigate('/settings')
        window.location.reload()
      }
    }

    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])

  return <div style={{ height: '100%', padding: 10 }}></div>
}
